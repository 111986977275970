// src/Home.js

import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// Corrige o problema com ícones padrão do Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});
function Instaladores() {
  
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Para redirecionar
  const title = "Instaladores";
  const footerText = "© 2024 All rights reserved.";
  // Localização do Centro do Brasil e marcadores
  const center = [-14.2350, -51.9253]; // Centro aproximado do Brasil
  
  const [markers, setmarkers] = useState([]);
  useEffect(() => {
    // Fazendo a chamada para a API
    fetch('https://api.streetfightermotoparts.com.br/api/instaladores/lista')
      .then(response => response.json()) // Converte a resposta para JSON
      .then(resposta => {
        if (!resposta.length) {
          navigate('/404'); // Redireciona para a página 404 se não houver dados
        } else {
          setmarkers(resposta); // Armazena os dados da resposta
          
        }
        setLoading(false); // Atualiza o estado para indicar que o carregamento terminou
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      });
  }, [navigate]); // Incluindo 'id' e 'navigate' no array de dependências

  if (loading) {
    return <div>Carregando...</div>;
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Welcome to the Home Page of our application." />
      </Helmet>
      <Header title={title} />
      <main>
      <MapContainer
          center={center}
          zoom={5}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {markers.map(marker => (
            <Marker key={marker.id} position={[marker.latitude, marker.longitude]}>
              <Popup>
                <strong>{marker.name}</strong><br />
                {marker.address}<br />
                {marker.city}, {marker.state}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Instaladores;
